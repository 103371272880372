export const people = [
    {
        name: "Logan White",
        role: "Co-Founder/Programmer",
        link: "https://www.linkedin.com/in/logan-white-/",
        image: "./loganImage.jpg",
        bio: "Logan is a gameplay engineer who has been programming since he was 15 years old, and since then he has gotten a lot better. Since starting this journey Logan has made a ½ dozen games across personal, academic, and professional projects. He is absolutely thrilled to be apart of the Struggled Seas team and can’t wait to show the world what the team has been working on."
    },
    {
        name: "Andrew Bradbury",
        role: "Co-Founder/Programmer",
        link: "http://www.linkedin.com/in/andrbrad",
        image: "./andrew.png",
        bio: "Andrew is the genius behind most of the incredible physics that you see in our games. He is a graduate from Rochester Institute of Technology and has multiple titles to his name. In his personal time Andrew is a big fan of staying fit and working out as well Dugeons and Dragons. He is very excited to be a part of this team and so excited to show the world his latest progress."
    },
    {
        name: "Matt Cioni",
        role: "Programmer",
        link: "https://www.linkedin.com/in/matthew-cioni/",
        image: "./matt.jpg",
        bio: "Matt is a gameplay engineer and the sound engineer for Struggled Seas Studios. He is currently a student at Rochester Institute of Technology, having participated in multiple game projects during his academic career, including a project with the current team. Matt loves balancing the musical and technical aspects in the projects he is a part of. He is very excited to be working with this team again and can't wait to show the world what Struggled Seas can create. "
    },
    {
        name: "Sean Smolen",
        role: "World Artist",
        link: "https://www.linkedin.com/in/sean-s-33b30312b/",
        image: "./Sean.jpg",
        bio: "Sean is an Environment Artists who graduated from Purdue in 2021. After meeting the team at GDC 2023 he and the team worked on a game jam and from there on has been dedicated to creating a beautiful stylized world with an enthralling story. Drawing most of his inspiration from early 2000s PS2 games he is committed to bringing back that spark that seems to have disappeared from modern games." 
    },
    {
        name: "Jess Southworth",
        role: "Character Artist",
        link: "https://www.linkedin.com/in/jessica-southworth-112985223/",
        image: "./jess.png",
        bio: "Jess is a 3D artist with a love for visual story telling and a focus on creating 3D characters and props. She has experience using Maya, Zbrush, X-Gen, Substance 3D Painter, Substance 3D Designer, and implementing other Adobe  programs such as Illustrator and Photoshop into her workflow. She has a lot of fun creating 2D concepts in her spare time as well as working out how to best optimize 3D assets in-engine."
    },
    {
        name: "Lauren Paige",
        role: "Chief Producer",
        link: "https://www.linkedin.com/in/laurenevelynpaige/",
        image: "./Lauren.jpg",
        bio: "Lauren is a 23 year-old  full-time Dev Support & QA Tester with Naughty Dog, currently living in Boston, MA. She is the producer of the group, bringing her knowledge of the industry to help organize the team to its maximum potential. Lauren loves being able to make a project fun and efficient all while making sure everyone feels heard at every step of the way. Besides developing games, Lauren is a dog mom to a three year-old corgi named Sammy that is both the bane of her existence and her loving cuddle buddy."
    }
]

export const mission = [
    {
        mission: "To tell stories and explore new worlds.",
        tagline: "Life is better with a little adventure",
        image: "./MissionStatement.png"
    }
]

export const about = [
    {
        title: "About Us",
        about: "We are 'Struggled Seas Studios' a gathering of imaginative and driven developers united to narrate extraordinary tales and venture into imaginative realms. Our team continuously pushes the limits of what can be achieved, persistently striving to pioneer and delve into captivating aspects of the game development arena."
    }
]

export const games = [
    {
        name: "Phasebound: A Hero's Journey",
        image: "./Phasebound.png",
        description: "You step into the shoes of Nick Noble, an bright-eyed teenager who inadvertently crosses paths with one of the ancient gods. Bestowed with newfound powers, Nick finds himself thrust into a monumental struggle for the very soul of the world. In a timeless tale of heroism, justice, and the enduring spirit of humanity, you must guide Nick on a quest to restore balance and ensure the triumph of good over the forces of darkness.",
        link: "www.google.com"
    }
]